/*------------------------------------------------------------------------------
  Header
-------------------------------------------------------------------------------*/

.xvia-header-area {
  font-family: 'Montserrat';
  font-style: normal;
}

.xvia-header-topbar {
  background: linear-gradient(90.11deg, #FDB913 18.79%, #EF4123 105.05%);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 16px 16px;
  z-index: 10100 !important;
  position: relative;

  a, a:hover {
    padding: 0 16px;
    border-right: 1px solid #FFF;
    outline: none;
    text-decoration: none;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    color: white;


    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }
}

.xvia-header-section {
  display: flex;
  flex-direction: row;
  font-style: normal;
}

.xvia-header-info {
  display: inline-block;
  font-size: 14px;
  margin-right: 28px;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  span {
    font-weight: 700;
    color: #fff;
  }

  a {
    color: #fff;
  }

  svg {
    max-height: 14px;
  }
}

.xvia-header-quick-links {
  text-align: right;

  a {
    display: inline-block;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.9);
  }
}

.xvia-header-logo {
  img {
    height: 64px;
  }
}

.xvia-header-content {
  display: flex;
  background-color: #F9F9F9;
  background-image: url("../../assets/shape/header-mask.svg");
  background-repeat: no-repeat;
  background-position: center right;
}

.xvia-header-content-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.xvia-header-elements {
  display: flex;
  justify-content: flex-end;
}

.xvia-header-btn {
  button {
    display: block;
    color: #fff;
    background: #034EA2;
    padding: 0 38px;
    height: 74px;
    line-height: 60px;
    border-radius: 14px;
    outline: none;
    text-decoration: none;
    transition: all 0.3s linear 0s;
    border: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
  }

  &__inactive {
    button {
      color: #000;
      background: #f8f8f8;
      border: 1px solid #d8d8d8;
    }
  }

  &__loading {
    button {
      opacity: .2;
    }
  }
}

.xvia-header-btn--icon {
  margin-right: 10px;

  svg {
    max-height: 18px;
  }
}

.xvia-header-btn__chevron-icon {
  margin-left: 10px;
}

.xvia-header-center {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 0px 48px;
}


@media (max-width: 991px) {
  .xvia-header-topbar, .xvia-header-content  {
      padding: 0 15px;
  }

  .xvia-header-logo img {
    height: 56px;
  }

  .xvia-header-info {
    display: none;
  }

  .xvia-header-quick-links {
    margin-left: auto;
    a {
      font-size: 12px;
    }
  }

  .xvia-header-content {
    position: relative;
  }

  .xvia-header-elements {
    position: absolute;
    top: 24px;
    right: 16px;
  }

  .xvia-header-content-section {
    flex-direction: column;
  }

  .xvia-header-center {
    align-self: stretch;
    margin: 0;
  }

  .xvia-header-btn > svg {
    height: 36px;
    color: #000000;
  }

  .xvia-header-logo {
    align-self: flex-start;
    margin-bottom: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1501px) {
  .xvia-header-topbar, .xvia-header-content  {
      padding: 16px 80px;
  }
}

/* Large */
@media (min-width: 1501px) {
  .xvia-header-topbar, .xvia-header-content  {
      padding: 16px 100px;
  }

  .xvia-header-search {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin: 0px 48px;

    &__input {
      max-width: 700px;
    }
  }
}
