.xvia-header-area {
  font-family: "Montserrat";
  font-style: normal;
}

.xvia-header-content-generic {
  display: flex;
  background-color: #f9f9f9;
  background-repeat: no-repeat;
  background-position: center right;
}

@media (max-width: 991px) {
  .xvia-header-topbar,
  .xvia-header-content-generic {
    padding: 16px 15px;
  }

  .xvia-header-content-generic {
    position: relative;
  }
}

@media (min-width: 992px) and (max-width: 1501px) {
  .xvia-header-topbar,
  .xvia-header-content-generic {
    padding: 16px 80px;
  }
}

/* Large */
@media (min-width: 1501px) {
  .xvia-header-topbar,
  .xvia-header-content-generic {
    padding: 16px 100px;
  }
}
