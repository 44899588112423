.xvia-search-bar {
  font-family: Montserrat;
  margin: auto;

  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 6.98715px;
  padding: 4px 6px;

  display: flex;
  flex-direction: row;

  flex: 1;

  &__btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;

    background: #007932;
    border-radius: 7px;

    border-style: none;

    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    font-style: normal;
    color: white;
    cursor: pointer;

    svg {
      max-height: 18px;
    }
  }

  &__btn-icon {
    display: flex;
    background: transparent;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    gap: 10px;

    border-style: none;

    font-size: 16px;

    color: black;
    cursor: pointer;

    svg {
      max-height: 18px;
    }
  }

  &__input {
    flex: 1;
  }

  fieldset {
    border: 0 !important;
  }
}

@media (max-width: 1200px) {
  .xvia-search-bar {
    &__btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 24px;
      gap: 10px;

      background: transparent;
      border-radius: 7px;

      border-style: none;

      font-family: Montserrat;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      font-style: normal;
      color: #545454;
      cursor: pointer;
    }
  }

  .xvia-search-bar__btn-text, .xvia-search-bar__select {
    display: none !important;
  }
}
