/*------------------------------------------------------------------------------
  Template Name: Findup – Directory & Listing HTML5 Template
-------------------------------------------------------------------------------*/

@import 'header';
@import 'content';
@import 'search';

.xvia-main-app, #xvia-main {
  height: 100%;
}

.xvia-main-app {
  display: flex;
  flex-direction: column;

  .xvia-main-app__wrapp-content-area {
    display: flex;
    background-color: #f8f8f8;
  }

  .xvia-content-section {
    display: flex;
    flex: 1;
    margin: auto;
  }
}

